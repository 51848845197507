export enum SPECS {
  CASHIER_EXPRESS_IN_PRODUCT_PAGE = 'specs.stores.CashierExpressInProductPage',
  ENABLE_LOST_BUSINESS_EMAIL = 'specs.stores.EnableLostBusinessEmail',
  SELLING_IN_UNITS_SF = 'specs.stores.SellingInUnitsSF',
  USE_LIGHTBOXES = 'specs.stores.UseLightboxes',
  MIGRATE_CATALOG_SERVER = 'specs.stores.MigrateCatalogServer',
  PRODUCT_OPTIONS_IN_GALLERY_COMP = 'specs.stores.ProductOptionsInGalleryComp',
  URL_CUSTOMIZE = 'specs.stores.EnableUrlCustomizeInProductPage',
  PRODUCT_PAYMENT_OPTIONS_BREAKDOWN = 'specs.stores.ProductPaymentOptionsBreakdown',
}
